@function f($start) {
  $totalAnimationTime: 10;
  @return percentage(calc($start/$totalAnimationTime));
}

@mixin anim($from: 0, $to) {
  @content;
}

#png-cursor {
  transform-origin: top left;
  animation: png-cursor 10s infinite ease-in-out;
}
@keyframes png-cursor {
  #{f(0)} {
    transform: scale(1);
  }
  #{f(0.1)} {
    transform: scale(0.9);
  }
  #{f(0.2)} {
    transform: scale(1);
  }
  #{f(0.9)} {
    transform: translate(1870%, -430%);
  }
  #{f(1)} {
    transform: translate(1870%, -430%) scale(0.9);
  }
  #{f(1.1)} {
    transform: translate(1870%, -430%) scale(1);
  }
  #{f(3.1)} {
    transform: translate(1870%, -430%);
  }
  #{f(4.1)} {
    transform: translate(-270%, -540%);
  }
  #{f(4.3)} {
    transform: translate(-270%, -540%) scale(0.9);
  }
  #{f(4.4)} {
    transform: translate(-270%, -540%) scale(1);
  }
  #{f(6.5)} {
    transform: translate(-270%, -540%);
  }
  #{f(7.3)} {
    transform: translate(780%, -720%);
  }
  #{f(8.4)} {
    transform: translate(780%, -720%);
  }
  #{f(9)} {
    transform: translate(0, 0);
  }
}

#png-arcs {
  transform-origin: top left;
  animation: png-arcs 10s infinite ease-in-out;
}
@keyframes png-arcs {
  #{f(0.3)} {
    transform: translate(0, 0);
  }
  #{f(0.8)} {
    transform: translate(0, -46%);
  }
  #{f(3)} {
    transform: translate(0, -46%);
  }
  #{f(3.1)} {
    transform: translate(0, 0);
  }
}

#png-switch-on {
  opacity: 0;
  transform-origin: top left;
  animation: png-switch-on 10s infinite ease-in-out;
}
@keyframes png-switch-on {
  #{f(1.1)} {
    opacity: 0;
  }
  #{f(1.3)} {
    opacity: 1;
  }
  #{f(3)} {
    opacity: 1;
  }
  #{f(3.1)} {
    opacity: 0;
  }
}

#png-zillow {
  animation: png-zillow 10s infinite ease-in;
}
@keyframes png-zillow {
  #{f(1.3)} {
    transform: translate(0, 0);
    opacity: 1;
  }
  #{f(2.2)} {
    transform: translate(-300%, 170%);
    opacity: 0;
  }
  #{f(5)} {
    transform: translate(0, 0);
    transform: scale(0.8);
    opacity: 0;
  }
  #{f(8.4)} {
    opacity: 0;
    transform: scale(0.8);
  }
  #{f(8.7)} {
    opacity: 1;
    transform: scale(1);
  }
}

#png-notifications {
  animation: png-notifications 10s infinite ease-in;
}
@keyframes png-notifications {
  #{f(1.3)} {
    transform: translate(0, 0);
    opacity: 1;
  }
  #{f(2.2)} {
    transform: translate(-160%, 10%);
    opacity: 0;
  }
  #{f(5)} {
    transform: translate(0, 0);
    transform: scale(0.8);
    opacity: 0;
  }
  #{f(6.6)} {
    opacity: 0;
    transform: scale(0.8);
  }
  #{f(6.9)} {
    opacity: 1;
    transform: scale(1);
  }
}

#png-feedback {
  animation: png-feedback 10s infinite ease-in;
}
@keyframes png-feedback {
  #{f(1.3)} {
    transform: translate(0, 0);
    opacity: 1;
  }
  #{f(2.2)} {
    transform: translate(160%, -145%);
    opacity: 0;
  }
  #{f(5)} {
    transform: translate(0, 0);
    transform: scale(0.8);
    opacity: 0;
  }
  #{f(6.9)} {
    opacity: 0;
    transform: scale(0.8);
  }
  #{f(7.2)} {
    opacity: 1;
    transform: scale(1);
  }
}

#png-quickbooks {
  animation: png-quickbooks 10s infinite ease-in;
}
@keyframes png-quickbooks {
  #{f(1.3)} {
    transform: translate(0, 0);
    opacity: 1;
  }
  #{f(2.2)} {
    transform: translate(410%, -15%);
    opacity: 0;
  }
  #{f(5)} {
    transform: translate(0, 0);
    transform: scale(0.8);
    opacity: 0;
  }
  #{f(7.6)} {
    opacity: 0;
    transform: scale(0.8);
  }
  #{f(7.9)} {
    opacity: 1;
    transform: scale(1);
  }
}

#png-financials {
  animation: png-financials 10s infinite ease-in;
}
@keyframes png-financials {
  #{f(1.3)} {
    transform: translate(0, 0);
    opacity: 1;
  }
  #{f(2.2)} {
    transform: translate(90%, 135%);
    opacity: 0;
  }

  #{f(5)} {
    transform: translate(0, 0);
    transform: scale(0.8);
    opacity: 0;
  }
  #{f(6.9)} {
    opacity: 0;
    transform: scale(0.8);
  }
  #{f(7.2)} {
    opacity: 1;
    transform: scale(1);
  }
}
#png-calendar {
  animation: png-calendar 10s infinite ease-in;
}
@keyframes png-calendar {
  0% {
    transform: translate(-120%, 25%);
    opacity: 0;
  }
  #{f(2.1)} {
    transform: translate(-120%, 25%);
    opacity: 0;
  }
  #{f(3.1)} {
    transform: translate(0, 0);
    opacity: 1;
  }
  #{f(6.5)} {
    transform: scale(1);
    opacity: 1;
  }
  #{f(6.8)} {
    transform: scale(0.8);
    opacity: 0;
  }
  100% {
    transform: scale(0.8);
    opacity: 0;
  }
}
#png-google-maps {
  animation: png-google-maps 10s infinite ease-in;
}
@keyframes png-google-maps {
  0% {
    transform: translate(180%, 140%);
    opacity: 0;
  }
  #{f(2.1)} {
    transform: translate(180%, 140%);
    opacity: 0;
  }
  #{f(3.1)} {
    transform: translate(0, 0);
    opacity: 1;
  }
  #{f(6.5)} {
    transform: scale(1);
    opacity: 1;
  }
  #{f(6.8)} {
    transform: scale(0.8);
    opacity: 0;
  }
  100% {
    transform: scale(0.8);
    opacity: 0;
  }
}

#png-stripe {
  animation: png-stripe 10s infinite ease-in;
}
@keyframes png-stripe {
  0% {
    transform: translate(430%, 0%);
    opacity: 0;
  }
  #{f(2.1)} {
    transform: translate(430%, 0%);
    opacity: 0;
  }
  #{f(3.1)} {
    transform: translate(0, 0);
    opacity: 1;
  }
  #{f(6.5)} {
    transform: scale(1);
    opacity: 1;
  }
  #{f(6.8)} {
    transform: scale(0.8);
    opacity: 0;
  }
  100% {
    transform: scale(0.8);
    opacity: 0;
  }
}

#png-closing-alert {
  animation: png-closing-alert 10s infinite ease-in;
}
@keyframes png-closing-alert {
  0% {
    transform: translate(50%, -120%);
    opacity: 0;
  }
  #{f(2.1)} {
    transform: translate(50%, -120%);
    opacity: 0;
  }
  #{f(3.1)} {
    transform: translate(0, 0);
    opacity: 1;
  }
  #{f(6.5)} {
    transform: scale(1);
    opacity: 1;
  }
  #{f(6.8)} {
    transform: scale(0.8);
    opacity: 0;
  }
  100% {
    transform: scale(0.8);
    opacity: 0;
  }
}

#png-threads-selected {
  opacity: 0;
  animation: png-threads-selected 10s infinite ease-in;
}
@keyframes png-threads-selected {
  #{f(4.3)} {
    opacity: 0;
  }
  #{f(4.4)} {
    opacity: 1;
  }
  #{f(10)} {
    opacity: 1;
  }
}
#png-thread {
  animation: png-thread 10s infinite ease-in;
}
@keyframes png-thread {
  #{f(4.6)} {
    transform: translate(0, 0);
  }
  #{f(5.5)} {
    transform: translate(0, -33%);
  }
  100% {
    transform: translate(0, -33%);
  }
}
#png-arcs-base {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  transform-origin: top left;
  animation: png-arcs-base 10s infinite ease-in;
}
@keyframes png-arcs-base {
  #{f(1.3)} {
    transform: translate(0, 0);
    opacity: 1;
  }
  #{f(2.2)} {
    transform: translate(-35%, 0);
    opacity: 0;
  }
  #{f(6.5)} {
    transform: translate(-35%, 0);
    opacity: 0;
  }
  #{f(7.3)} {
    transform: translate(0, 0);
    opacity: 1;
  }
}
#png-thread-base {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  transform-origin: center;
  animation: png-thread-base 10s infinite ease-in;
}
@keyframes png-thread-base {
  #{f(0)} {
    transform: translate(35%, 0);
    opacity: 0;
  }
  #{f(2)} {
    transform: translate(35%, 0);
    opacity: 0;
  }
  #{f(2.8)} {
    transform: translate(0, 0);
    opacity: 1;
  }
  #{f(6.5)} {
    transform: translate(0, 0);
    opacity: 1;
  }
  #{f(7.3)} {
    transform: translate(35%, 0);
    opacity: 0;
  }
  100% {
    transform: translate(35%, 0);
    opacity: 0;
  }
}
