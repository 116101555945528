@function f($start) {
  $totalAnimationTime: 10;
  @return percentage(calc($start/$totalAnimationTime));
}

@mixin anim($from: 0, $to) {
  @content;
}

#feedback,
#qb,
#financials,
#zillow,
#notifications,
#cursor,
#closing_alert,
#calendar,
#stripe,
#google_maps {
  transform-box: fill-box;
  transform-origin: center;
}

#cursor {
  transform-origin: top left;
  animation: cursor 10s infinite ease-in-out;
}
@keyframes cursor {
  #{f(0)} {
    transform: scale(1);
  }
  #{f(0.1)} {
    transform: scale(0.9);
  }
  #{f(0.2)} {
    transform: scale(1);
  }

  #{f(0.9)} {
    transform: translate(645px, -185px);
  }
  #{f(1)} {
    transform: translate(645px, -185px) scale(0.9);
  }
  #{f(1.1)} {
    transform: translate(645px, -185px) scale(1);
  }

  #{f(3.1)} {
    transform: translate(645px, -185px);
  }
  #{f(4.1)} {
    transform: translate(-110px, -240px);
  }
  #{f(4.3)} {
    transform: translate(-110px, -240px) scale(0.9);
  }
  #{f(4.4)} {
    transform: translate(-110px, -240px) scale(1);
  }

  #{f(6.5)} {
    transform: translate(-110px, -240px);
  }
  #{f(7.3)} {
    transform: translate(310px, -340px);
  }

  #{f(8.4)} {
    transform: translate(310px, -340px);
  }
  #{f(9)} {
    transform: translate(0, 0);
  }
}

#arc_list {
  transform-origin: top left;
  animation: arc_list 10s infinite ease-out;
  transform: translate(0, 130px);
  clip-path: inset(0px 0px 230px 0px);
}
@keyframes arc_list {
  #{f(0.3)} {
    transform: translate(0, 130px);
    clip-path: inset(0px 0px 230px 0px);
  }
  #{f(0.8)} {
    transform: translate(0, -100px);
    clip-path: inset(225px 0px 0px 0px);
  }

  #{f(3)} {
    transform: translate(0, -100px);
    clip-path: inset(225px 0px 0px 0px);
  }
  #{f(3.1)} {
    transform: translate(0, 130px);
    clip-path: inset(0px 0px 230px 0px);
  }
}

#off_switch {
  transform-origin: top left;
  animation: off_switch 10s infinite ease-out;
}
@keyframes off_switch {
  #{f(1.1)} {
    opacity: 1;
  }
  #{f(1.3)} {
    opacity: 0;
  }

  #{f(3)} {
    opacity: 0;
  }
  #{f(3.1)} {
    opacity: 1;
  }
}

#main_2 {
  transform-origin: top left;
  animation: main_2 10s infinite ease-in;
}
@keyframes main_2 {
  #{f(1.3)} {
    transform: translate(0, 0);
    opacity: 1;
  }
  #{f(2.2)} {
    transform: translate(-300px, 0);
    opacity: 0;
  }

  #{f(6.5)} {
    transform: translate(-300px, 0);
    opacity: 0;
  }
  #{f(7.3)} {
    transform: translate(0, 0);
    opacity: 1;
  }
}

#notifications {
  animation: notifications 10s infinite ease-in;
}
@keyframes notifications {
  #{f(1.3)} {
    transform: translate(0, 0);
  }
  #{f(2.1)} {
    transform: translate(-320px, 0px);
    opacity: 1;
  }
  #{f(2.2)} {
    transform: translate(-320px, 0px);
    opacity: 0;
  }

  #{f(5)} {
    transform: translate(0, 0);
    transform: scale(0.8);
    opacity: 0;
  }
  #{f(6.6)} {
    opacity: 0;
    transform: scale(0.8);
  }
  #{f(6.9)} {
    opacity: 1;
    transform: scale(1);
  }
}

#feedback {
  animation: feedback 10s infinite ease-in;
}
@keyframes feedback {
  #{f(1.3)} {
    transform: translate(0, 0);
  }
  #{f(2.1)} {
    transform: translate(242px, -200px);
    opacity: 1;
  }
  #{f(2.2)} {
    transform: translate(242px, -200px);
    opacity: 0;
  }

  #{f(5)} {
    transform: translate(0, 0);
    transform: scale(0.8);
    opacity: 0;
  }
  #{f(6.9)} {
    opacity: 0;
    transform: scale(0.8);
  }
  #{f(7.2)} {
    opacity: 1;
    transform: scale(1);
  }
}

#financials {
  animation: financials 10s infinite ease-in;
}
@keyframes financials {
  #{f(1.3)} {
    transform: translate(0, 0);
  }
  #{f(2.1)} {
    transform: translate(220px, 150px);
    opacity: 1;
  }
  #{f(2.2)} {
    transform: translate(220px, 150px);
    opacity: 0;
  }

  #{f(5)} {
    transform: translate(0, 0);
    transform: scale(0.8);
    opacity: 0;
  }
  #{f(7.2)} {
    opacity: 0;
    transform: scale(0.8);
  }
  #{f(7.5)} {
    opacity: 1;
    transform: scale(1);
  }
}

#qb {
  animation: qb 10s infinite ease-in;
}
@keyframes qb {
  #{f(1.3)} {
    transform: translate(0, 0);
  }
  #{f(2.1)} {
    transform: translate(348px, 0px);
    opacity: 1;
  }
  #{f(2.2)} {
    transform: translate(348px, 0px);
    opacity: 0;
  }

  #{f(5)} {
    transform: translate(0, 0);
    transform: scale(0.8);
    opacity: 0;
  }
  #{f(7.6)} {
    opacity: 0;
    transform: scale(0.8);
  }
  #{f(7.9)} {
    opacity: 1;
    transform: scale(1);
  }
}

#zillow {
  animation: zillow 10s infinite ease-in;
}
@keyframes zillow {
  #{f(1.3)} {
    transform: translate(0, 0);
  }
  #{f(2.1)} {
    transform: translate(-290px, 140px);
    opacity: 1;
  }
  #{f(2.2)} {
    transform: translate(-290px, 140px);
    opacity: 0;
  }

  #{f(5)} {
    transform: translate(0, 0);
    transform: scale(0.8);
    opacity: 0;
  }
  #{f(8.4)} {
    opacity: 0;
    transform: scale(0.8);
  }
  #{f(8.7)} {
    opacity: 1;
    transform: scale(1);
  }
}

#calendar {
  animation: calendar 10s infinite ease-out;
}
@keyframes calendar {
  0% {
    transform: translate(-380px, 85px);
    opacity: 0;
  }
  #{f(2.1)} {
    transform: translate(-380px, 85px);
    opacity: 0;
  }
  #{f(2.2)} {
    transform: translate(-380px, 85px);
    opacity: 1;
  }
  #{f(3.1)} {
    transform: translate(0, 0);
  }

  #{f(6.5)} {
    transform: scale(1);
    opacity: 1;
  }
  #{f(6.8)} {
    transform: scale(0.8);
    opacity: 0;
  }
  100% {
    transform: scale(0.8);
    opacity: 0;
  }
}

#google_maps {
  animation: google_maps 10s infinite ease-out;
}
@keyframes google_maps {
  0% {
    transform: translate(180px, 90px);
    opacity: 0;
  }
  #{f(2.1)} {
    transform: translate(180px, 90px);
    opacity: 0;
  }
  #{f(2.2)} {
    transform: translate(180px, 90px);
    opacity: 1;
  }
  #{f(3.1)} {
    transform: translate(0, 0);
  }

  #{f(6.5)} {
    transform: scale(1);
    opacity: 1;
  }
  #{f(6.8)} {
    transform: scale(0.8);
    opacity: 0;
  }
  100% {
    transform: scale(0.8);
    opacity: 0;
  }
}

#stripe {
  animation: stripe 10s infinite ease-out;
}
@keyframes stripe {
  0% {
    transform: translate(400px, 0px);
    opacity: 0;
  }
  #{f(2.1)} {
    transform: translate(400px, 0px);
    opacity: 0;
  }
  #{f(2.2)} {
    transform: translate(400px, 0px);
    opacity: 1;
  }
  #{f(3.1)} {
    transform: translate(0, 0);
  }

  #{f(6.5)} {
    transform: scale(1);
    opacity: 1;
  }
  #{f(6.8)} {
    transform: scale(0.8);
    opacity: 0;
  }
  100% {
    transform: scale(0.8);
    opacity: 0;
  }
}

#closing_alert {
  animation: closing_alert 10s infinite ease-out;
}
@keyframes closing_alert {
  0% {
    transform: translate(150px, -190px);
    opacity: 0;
  }
  #{f(2.1)} {
    transform: translate(150px, -190px);
    opacity: 0;
  }
  #{f(2.2)} {
    transform: translate(150px, -190px);
    opacity: 1;
  }
  #{f(3.1)} {
    transform: translate(0, 0);
  }

  #{f(6.5)} {
    transform: scale(1);
    opacity: 1;
  }
  #{f(6.8)} {
    transform: scale(0.8);
    opacity: 0;
  }
  100% {
    transform: scale(0.8);
    opacity: 0;
  }
}

#main {
  transform-origin: center;
  animation: main 10s infinite ease-out;
}
@keyframes main {
  #{f(0)} {
    transform: translate(300px, 0);
    opacity: 0;
  }
  #{f(2)} {
    transform: translate(300px, 0);
    opacity: 0;
  }
  #{f(2.8)} {
    transform: translate(0, 0);
    opacity: 1;
  }

  #{f(6.5)} {
    transform: translate(0, 0);
    opacity: 1;
  }
  #{f(7.3)} {
    transform: translate(300px, 0);
    opacity: 0;
  }
  100% {
    transform: translate(300px, 0);
    opacity: 0;
  }
}

#felicity_click {
  transform-origin: center;
  animation: felicity_click 10s infinite ease-out;
}
@keyframes felicity_click {
  #{f(0)} {
    fill: #f5f5f5;
  }
  #{f(4.3)} {
    fill: #f5f5f5;
  }
  #{f(4.4)} {
    fill: #ffffff;
  }
}

#conversation {
  transform-origin: center;
  animation: conversation 10s infinite ease-out;
  transform: translate(0, 60px);
}
@keyframes conversation {
  #{f(4.6)} {
    transform: translate(0, 60px);
    clip-path: inset(0 0 180px 0);
  }
  #{f(5.5)} {
    transform: translate(0, -130px);
    clip-path: inset(190px 0 0 0);
  }
  100% {
    transform: translate(0, -130px);
    clip-path: inset(190px 0 0 0);
  }
}

#orb_flourish,
#orb_flourish_2 {
  transform-origin: center;
  animation: orbs 10s infinite ease-out;
}
@keyframes orbs {
  0% {
    opacity: 0;
  }
  #{f(2.2)} {
    opacity: 0;
  }
  #{f(2.7)} {
    opacity: 1;
  }

  #{f(6.5)} {
    opacity: 1;
  }
  #{f(7)} {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

#orb_flourish_3,
#orb_flourish_4 {
  transform-origin: center;
  animation: orbs2 10s infinite ease-out;
}
@keyframes orbs2 {
  #{f(1.3)} {
    opacity: 1;
  }
  #{f(1.8)} {
    opacity: 0;
  }

  #{f(6.6)} {
    opacity: 0;
  }
  #{f(7.1)} {
    opacity: 1;
  }
}

#threads {
  clip-path: inset(0 0 50px 0);
}
