@use './svg-animation.scss';
@use './png-animation.scss';

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply text-base;
  @apply bg-gray;
}

html,
body,
#root {
  height: 100%;
}

input,
textarea {
  outline: none;
}

.u-spread {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

